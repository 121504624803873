import {Button, Flex, Table} from "antd";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import BankAccountEdit from "./BankAccountEdit";
import {useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import dayjs from "dayjs";

const BankAccount = () => {
    const [bankAccountEditOpen, setBankAccountEditOpen] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankEditInfo, setBankEditInfo] = useState(null);

    const columns = [
        {
            title: 'Tên tài khoản',
            dataIndex: 'receiver_name',
            key: 'receiver_name',
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'bank_account',
            key: 'bank_account',
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: 'Chi nhánh',
            dataIndex: 'bank_branch',
            key: 'bank_branch',
        },
        {
            title: 'Tỉnh/TP',
            dataIndex: 'bank_province',
            key: 'bank_province',
        },
        {
            title: 'Ngày tạo',
            key: 'datetime',
            render: (text) => (
                dayjs(text.datetime).format('DD/MM/YYYY HH:mm')
            ),
        },
        {
            title: '#',
            key: 'action',
            render: (_, record) => (
                    <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => setBankEditInfo(record)}
                    />
            )
        }
    ]

    const fetchData = () => {
        api.get('/bank-account').then(response => {
            setBankAccounts(response)
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    const handleClose = (reload = false) => {
        setBankAccountEditOpen(false);
        setBankEditInfo(null);
        if (reload) {
            fetchData();
        }
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
            >
                <h1>Danh sách tài khoản mẫu</h1>
                <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => setBankAccountEditOpen(true)}
                >
                    Thêm tài khoản
                </Button>
            </Flex>
            <Table
                dataSource={bankAccounts}
                columns={columns}
                rowKey={(record) => record._id}
            />
            <BankAccountEdit
                open={bankAccountEditOpen || !!bankEditInfo}
                editInfo={bankEditInfo}
                onClose={handleClose}
            />
        </>
    );
}

export default BankAccount;