import * as XLSX from 'xlsx';

// Function to generate and download Excel file from array of objects
export const exportToExcel = (data, fileName = 'exported_data') => {

    try {
        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert array of objects to worksheet
        const ws = XLSX.utils.json_to_sheet(data);

        const headers = Object.keys(data[0]) || [];

        const columnIndex = headers.indexOf('bank_account');
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let row = range.s.r + 1; row <= range.e.r; row++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: columnIndex });
            if (ws[cellAddress]) {
                //console.log(ws[cellAddress]);
                // Explicitly set the cell type to string
                ws[cellAddress].t = 's'; // 's' for string
                ws[cellAddress].z = '@'
            }
        }

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate Excel file and trigger download
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
        console.error('Error exporting to Excel:', error.message);
        throw new Error('Failed to export Excel file');
    }
};

// Function to parse uploaded Excel file to array of objects
export const parseExcelFile = (file) => {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();

            reader.onload = (e) => {
                // Get binary data
                const data = new Uint8Array(e.target.result);

                // Read workbook
                const wb = XLSX.read(data, { type: 'array' });

                // Get first worksheet
                const ws = wb.Sheets[wb.SheetNames[0]];

                // Convert worksheet to array of objects
                const jsonData = XLSX.utils.sheet_to_json(ws);

                resolve(jsonData);
            };

            reader.onerror = (error) => {
                reject(new Error('Failed to read file: ' + error.message));
            };

            // Read file as array buffer
            reader.readAsArrayBuffer(file);
        } catch (error) {
            reject(new Error('Error parsing Excel file: ' + error.message));
        }
    });
};