import {useCallback, useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import {UseLoading} from "../../Helpers/loading-spinner-helper";
import {REQUEST_STATUS} from "./const";
import {Button, Table, Tag, App, Space, Checkbox} from "antd";
import {formatNumber} from "../../Helpers/number-helper";
import {
    DeleteOutlined,
    DownloadOutlined,
    ExclamationCircleFilled,
    FilterOutlined,
    TagOutlined,
} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import {useNavigate} from "react-router-dom";
import {utils, writeFileXLSX} from "xlsx";

import dayjs from "dayjs";
import {ExpandableText} from "../../Helpers/common-helper";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */


const RequestList = ({tabKey, reload, onEdit, search}) => {
    const {modal} = App.useApp();

    const tabList = {
        my_request: {
            endPoint: '/request/list'
        },
        admin_request: {
            endPoint: '/request/admin/list'
        }
    }

    const deleteRequest = (_id, request_code) => {
        modal.confirm({
            title: 'Xoá dữ liệu',
            icon: <ExclamationCircleFilled/>,
            content: (<span>Có chắc chắn xoá đề nghị <strong>{request_code}</strong> này không?</span>),
            okText: 'Đồng ý xoá',
            okType: 'danger',
            cancelText: 'Bỏ qua',
            onOk: () => {
                setLoading(true);
                api.post(`/request/delete`, {_id}).then(resp => {
                    if (resp) {
                        notify('success', resp);
                        fetchData();
                    }
                }).finally(() => {
                    setLoading(false)
                })
            },
            onCancel() {
            },
        });
    }

    const navigate = useNavigate();
    const editRequest = (request) => {
        if (tabKey !== 'my_request') {
            navigate(`/admin/request/${request._id}`)
        } else {
            onEdit(request);
        }
    }

    const requestStatusFilter = Object.keys(REQUEST_STATUS).map((status) => {
        if (status === 'cancelled')
            return false;

        return {
            label: <Space><TagOutlined style={{color: REQUEST_STATUS[status].color}}/> {REQUEST_STATUS[status].label}
            </Space>,
            value: REQUEST_STATUS[status].code
        }
    }).filter(Boolean);

    const [filtersItem, setFiltersItem] = useState([])
    const [radioValue, setRadioValue] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)
    const [sortByDate, setSortByDate] = useState(null)

    const handleFilter = () => {
        setFilterOpen(false)
        setFiltersItem(radioValue)

        fetchData(tabList[tabKey].endPoint, 1, 10, {request_status: radioValue})
    }
    const filterDropdown = () => (
        <div
            style={{
                padding: 8,
                maxWidth: 300,
                width: 300,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            {/*Display list of filtersItem as multiple radio group*/}
            <div className="text-bold">Tình trạng</div>
            <Checkbox.Group
                onChange={(values) => {
                    setRadioValue(values)
                }}
                value={radioValue}
            >
                <Space
                    className="m-3"
                    direction="vertical"
                >
                    {requestStatusFilter.map((filter) => (
                        <Checkbox className="link-text" key={filter.value}
                                  value={filter.value}>{filter.label}</Checkbox>
                    ))}
                </Space>
            </Checkbox.Group>
            <hr/>
            <div className="items-start-end">
                <Button type="link" onClick={() => setRadioValue([])}>Xóa chọn</Button>
                <Button type="primary" size="small" onClick={handleFilter}>Lọc</Button>
            </div>
        </div>
    )

    const setLoading = UseLoading(state => state.setLoading);
    const [dataList, setDataList] = useState([]);

    const exportFile = useCallback(() => {
            const excelData = dataList?.documents.map((row, index) => {
                    return columns.filter((column) => !column.noExport).reduce((obj, column) => {
                        let value = row[column.dataIndex];

                        if (column.export_rule) {
                            value = column.export_rule(row);
                        }

                        obj[column.label] = value;
                        return obj;
                    }, {STT: index + 1})
                }
            );

            const ws = utils.json_to_sheet(excelData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");

            //Generate file name based on date
            const date = new Date();
            const fileName = `Danh sách đề nghị của tôi ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`;
            writeFileXLSX(wb, fileName);

            notify('success', 'Đã tải xuống file: ' + fileName, 3);
        }, [dataList.documents]
    )


    const columns = [
        {
            title: <Space className="items-start-end"><span>Mã đề nghị</span><Button onClick={exportFile}
                                                                                     icon={<DownloadOutlined/>}
                                                                                     size="small" type="text"
                                                                                     title="Tải về danh sách dạng file Excel"/></Space>,
            label: 'Mã đề nghị',
            dataIndex: 'request_code',
            key: 'request_code',
            render: (text, record) => {
                return (
                    <span className="link-text" onClick={() => editRequest(record)}>{record.request_code}</span>
                )
            }
        },
        {
            title: 'Phòng ban đề  nghị',
            label: 'Phòng ban đề  nghị',
            dataIndex: 'request_department',
            key: 'created_department',
        },
        {
            title: 'Người đề  nghị',
            label: 'Người đề  nghị',
            dataIndex: 'created_by_name',
            key: 'created_by_name',
        },
        {
            title: 'Loại chi phí',
            label: 'Loại chi phí',
            dataIndex: 'expense_type',
            key: 'expense_type',
        },
        {
            title: 'IPO/DPO',
            label: 'IPO/DPO/LSX/Mã đơn',
            dataIndex: 'special_code',
            key: 'special_code',
        },
        {
            title: 'Mã PO',
            label: 'Mã PO',
            dataIndex: 'erp_code',
            key: 'erp_code',
        },
        {
            title: 'Ngày đi tiền',
            label: 'Ngày đi tiền',
            dataIndex: 'approved_request_payment_date',
            key: 'approved_request_payment_date',
            align: 'center',
            render: (text, record) => {
                return (
                    <span>{record.approved_request_payment_date ? dayjs(record.approved_request_payment_date).format('DD-MM-YYYY') : ''}</span>
                )
            }
        },
        {
            title: 'Diễn giải',
            label: 'Diễn giải',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => <ExpandableText text={record.description}/>
        },
        {
            title: 'Số tiền',
            label: 'Số tiền',
            dataIndex: 'request_amount',
            key: 'request_amount',
            align: 'right',
            render: (text, record) => (
                <span>{formatNumber(record.request_amount)}</span>
            )
        },
        {
            title: 'Loại tiền',
            label: 'Loại tiền',
            dataIndex: 'currency_code',
            align: 'center',
            render: (text, record) => (
                <span>{record.currency_code ? record.currency_code : 'VND'}</span>
            )
        },
        {
            title: 'Tình trạng',
            label: 'Tình trạng',
            dataIndex: 'request_status',
            export_rule: (record) => (REQUEST_STATUS[record.request_status].label),
            render: (text, record) => {
                let {label, color} = REQUEST_STATUS[record.request_status];
                return (
                    <Tag color={color}>{label}</Tag>
                )
            },
            filterIcon: (filtered) => <FilterOutlined style={{color: filtersItem?.length > 0 ? 'red' : undefined}}/>,
            filterDropdown: filterDropdown,
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setRadioValue(filtersItem);
                }

                setFilterOpen(visible)
            },
            filterDropdownOpen: filterOpen,

        },
        {
            title: 'Ngày tạo',
            label: 'Ngày tạo',
            dataIndex: 'created_at',
            export_rule: (value) => (dayjs(value.created_at).format('DD-MM-YYYY')),
            render: (text, record) => (
                <span>{dayjs(record.created_at).format('DD-MM-YYYY')}</span>
            ),
            sorter: true
        },
        {
            title: (<span>{tabKey === 'my_request' ? 'Xoá' : 'Người xử lý'}</span>),
            noExport: true,
            dataIndex: '_id',
            align: 'center',
            tabKey: 'my-request',
            render: (text, record) => {
                // On Mar-10-25: Tắt chức năng xoá request nếu như bị trả lại từ Kế toán. Mục đích: Để lưu lại các chứng từ sai, phục vụ KT báo cáo

                if ((tabKey === 'my_request') && ((record.request_status === REQUEST_STATUS.new.code))){ // || (record.request_status === REQUEST_STATUS.return_to_requester.code))) {
                    return (
                        <Button danger size="small" onClick={() => deleteRequest(record._id, record.request_code)}
                                icon={<DeleteOutlined/>}/>
                    )
                }
                /*
                if (tabKey === 'admin_request') {
                    return (
                        <Tag
                            color={record?.accountant_info?.tagColor}>{record?.accountant_info?.accountant_fullname}</Tag>
                    )
                }

                 */
            }
        },
        //Below rows are for data export only
        {
            hidden: true,
            title: 'Tài khoản đi tiền',
            label: 'Tài khoản đi tiền',
            dataIndex: 'transfer_from_account',
        },
        {
            hidden: true,
            title: 'Phân loại thanh toán',
            label: 'Phân loại thanh toán',
            dataIndex: 'instruction',
        },
        {
            hidden: true,
            title: 'Mùa vụ',
            label: 'Mùa vụ',
            dataIndex: 'special_season',
        },
        {
            hidden: true,
            title: 'Ngày cần chi',
            label: 'Ngày cần chi',
            dataIndex: 'request_payment_date',
            export_rule: (value) => (dayjs(value.request_payment_date).format('DD-MM-YYYY')),
        },
        {
            hidden: true,
            title: 'Ngày đi tiền',
            label: 'Ngày đi tiền',
            dataIndex: 'approved_request_payment_date',
            export_rule: (value) => value ? (dayjs(value.approved_request_payment_date).format('DD-MM-YYYY')) : null
        },
    ]

    const fetchData = (endPoint, currentPage = 1, pageSize = 10) => {
        const tab = tabList[tabKey];
        if (tab) {
            setLoading(true);

            api.post(tab.endPoint, {
                currentPage,
                pageSize,
                filter: {search, request_status: radioValue},
                sortByDate
            }).then((response) => {
                if (response) {
                    setDataList(response);
                }
            }).finally(() => {
                setLoading(false)
            });
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order){
            setSortByDate(sorter.order === 'ascend' ? 'asc' : 'desc');
        } else {
            setSortByDate('none');
        }
    }

    useEffect(() => {
        fetchData();
    }, [tabKey, reload]);

    useEffect(() => {
        if ((search !== null) || sortByDate) {
            fetchData();
        }
    }, [search, sortByDate]);


    return (
        <div>
            <Table
                dataSource={dataList?.documents}
                columns={columns.filter((column) => column.tabKey !== tabKey)}
                pagination={{
                    onChange: ((page, pageSize) => {
                        fetchData(tabList[tabKey].endPoint, page, pageSize)
                    }),
                    total: dataList?.totalItems,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} dòng`,
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}

export default RequestList
