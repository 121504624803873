import {Button, Col, Modal, Row, Table} from "antd";
import {useState} from "react";
import api from "../../../Helpers/api-helper";

const AdminBankAccountUpload = ({data, onCancel}) => {
    const [syncResult, setSyncResult] = useState(null)

    const handleClose = () => {
        setSyncResult(null)
        onCancel(null);
    }

    const handleSync = () => {
        setLoading(true)

        api.post('/bank-account/admin/sync', {data}).then(response => {
            if (response) {
                console.log(response)
                setSyncResult(response)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const [loading, setLoading] = useState(false)

    const bulkResultHeaders = ['insertedCount', 'modifiedCount']
    const bulkResultLabels = {
        insertedCount: 'Số lượng tài khoản được thêm mới',
        modifiedCount: 'Số lượng tài khoản được sửa'
    }

    return (
        <>
            <Modal
                loading={loading}
                open={data}
                title="Tải lên danh sách tài khoản"
                closeIcon={!loading}
                maskClosable={!loading}
                width={700}
                onCancel={handleClose}
                footer={null}
            >
                <Row style={{margin: '20px 0px'}}>
                    <Col span={16}>
                        <span>
                            Số lượng tài khoản đồng bộ: {data?.length}
                        </span>
                    </Col>
                    <Col span={8}>
                        <Button
                            block
                            type="primary"
                            onClick={handleSync}
                            disabled={syncResult}
                        >
                            Đồng bộ
                        </Button>
                    </Col>
                </Row>
                <div style={{display: syncResult?.errors ? 'block' : 'none'}}>
                    <Row>
                        <Col span={24}>
                        <span>
                            Các dòng dữ liệu lỗi: {syncResult?.errors?.join(',')}
                        </span>
                        </Col>
                    </Row>
                    <Row>
                        {syncResult?.bulkResult && // Conditional rendering
                            bulkResultHeaders.map((header, index) => (
                                <Col span={24} key={index}>
                                    <span>{bulkResultLabels[header]} : {syncResult?.bulkResult[header]}</span>
                                </Col>
                            ))}

                    </Row>
                </div>
            </Modal>
        </>
    )
        ;
}

export default AdminBankAccountUpload;