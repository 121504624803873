import {Button, Checkbox, Col, DatePicker, Form, InputNumber, Modal, Row, Select, Space} from "antd";
import {isIterable, OptionsWithDescription} from "../../Helpers/common-helper";
import {useEffect, useState} from "react";
import {ClearOutlined} from "@ant-design/icons";
import {REQUEST_STATUS} from "./const";
import {IconMultiCurrency} from "../Icons";

import dayjs from "dayjs";
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */


const RequestFilter = ({open, onClose, onOk, config, onFilterChange}) => {
    const [form] = Form.useForm();
    const initialFilter = {
        request_department: "Tất cả",
        request_payment_date: null,
        approved_request_payment_date: null,
        transfer_from_account: "Tất cả",
        currency_code: "Tất cả",
        expense_type: "Tất cả",
        accountant_id: "Tất cả",
        request_status: "Tất cả",
        special_season: "Tất cả",
        min_amount: null,
        max_amount: null,
        created_at: null,
        user_fault: null,
        is_tndn: null
    }

    const [filter, setFilter] = useState(initialFilter);

    const defaultOptions = {
        name: 'Tất cả',
        label: 'Tất cả',
        value: 'Tất cả'
    }

    useEffect(() => {
        //get saved filter object from localStorage
        let savedFilter = localStorage.getItem('request_filter');
        //check if saved filter is not null
        if (savedFilter) {
            //parse saved filter object to JSON
            savedFilter = JSON.parse(savedFilter);

            savedFilter.request_payment_date = savedFilter.request_payment_date ? savedFilter.request_payment_date.map(date => dayjs(date, "YYYY-MM-DD")) : null;
            savedFilter.approved_request_payment_date = savedFilter.approved_request_payment_date ? savedFilter.approved_request_payment_date.map(date => dayjs(date,"YYYY-MM-DD")) : null;
            savedFilter.created_at = savedFilter.created_at ? savedFilter.created_at.map(date => dayjs(date,"YYYY-MM-DD")) : null;

            setFilter(savedFilter);
            form.setFieldsValue(savedFilter);
        } else {
            form.setFieldsValue(filter);
        }

    }, [open]);

    useEffect(() => {
        onFilterChange(filter);
    }, [filter]);

    const [userFault, setUserFault] = useState(false);

    const handleOk = () => {
        //saving filter object to localStorage
        const dates_fields = ['request_payment_date', 'approved_request_payment_date', 'created_at']
        dates_fields.forEach(dates => {
            console.log("dates: ", dates);
            if (filter[dates]) {
                filter[dates] = filter[dates].map(date => dayjs(date).format('YYYY-MM-DD'));
            }
        })

        localStorage.setItem('request_filter', JSON.stringify(filter));
        window.localStorage.setItem('adminPagination', JSON.stringify({currentPage: 1, pageSize: 10}))
        onOk();
    }

    const handleClearFilter = async () => {
        setFilter(initialFilter);
        //delete filter from localStorage
        localStorage.removeItem('request_filter');
        form.setFieldsValue(initialFilter);
        onFilterChange(filter);

        setTimeout(() => {
            //onOk();
        }, 200);
    }

    return (
        <Modal
            title="Thiết lập bộ lọc"
            centered
            open={open}
            onOk={handleOk}
            onCancel={onClose}
            width={700}
            footer={(
                <div className="items-start-end">
                    <Button icon={<ClearOutlined/>} onClick={handleClearFilter}>Xoá điều kiện lọc</Button>
                    <Space>
                        <Button onClick={onClose}>Đóng</Button>
                        <Button type="primary" onClick={handleOk}>Lọc thông tin</Button>
                    </Space>
                </div>
            )}
        >
            <Form
                layout="vertical"
                form={form}
            >
                <Row gutter={[16, 16]} className="mt-4">
                    <Col span={12}>
                        <Form.Item
                            label="Phòng ban"
                            name="request_department"
                        >
                            <Select
                                allowClear
                                style={{width: '100%'}}
                                onChange={(value) => setFilter({...filter, request_department: value})}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    defaultOptions,
                                    ...(isIterable(config?.department) ?
                                        config?.department?.map(item => ({
                                            label: item.name,
                                            value: item.name
                                        }))
                                        : [])
                                ]
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Tài khoản đi tiền"
                            name="transfer_from_account"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{width: '100%'}}
                                onChange={(value) => setFilter({...filter, transfer_from_account: value})}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    defaultOptions,
                                    ...(isIterable(config?.transfer_from_account) ?
                                        config?.transfer_from_account?.map(item => ({
                                            label: item.name,
                                            value: item.name
                                        }))
                                        : [])
                                ]
                                }
                            />
                        </Form.Item>

                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="">
                    <Col span={12}>
                        <Form.Item
                            name="request_status"
                            label="Tình trạng yêu cầu"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                onChange={(value) => setFilter({...filter, request_status: value})}
                                optionLabelProp="label"
                            >
                                {OptionsWithDescription([
                                        {
                                            name: 'Tất cả',
                                            value: ''
                                        },
                                        {
                                            name: REQUEST_STATUS.new.label,
                                            value: REQUEST_STATUS.new.code
                                        },
                                        {
                                            name: REQUEST_STATUS.return_to_requester.label,
                                            value: REQUEST_STATUS.return_to_requester.code
                                        },
                                        {
                                            name: REQUEST_STATUS.pending.label,
                                            value: REQUEST_STATUS.pending.code
                                        },
                                        {
                                            name: REQUEST_STATUS.waiting_for_process.label,
                                            value: REQUEST_STATUS.waiting_for_process.code
                                        },
                                        {
                                            name: REQUEST_STATUS.full_payment.label,
                                            value: REQUEST_STATUS.full_payment.code
                                        },
                                        {
                                            name: REQUEST_STATUS.partial_payment.label,
                                            value: REQUEST_STATUS.partial_payment.code
                                        },
                                        {
                                            name: REQUEST_STATUS.finished.label,
                                            value: REQUEST_STATUS.finished.code
                                        }
                                    ],
                                    {label: 'name', value: 'value', description: 'description'})}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="currency_code"
                            label="Loại tiền"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                onChange={(value) => setFilter({...filter, currency_code: value})}
                                optionLabelProp="value"
                            >
                                {OptionsWithDescription([
                                        {
                                            currency: 'Tất cả',
                                            value: null
                                        },
                                        ...(isIterable(config?.rates) ?
                                            config?.rates
                                            : [])
                                    ],
                                    {label: 'currency', value: 'currency', description: 'currency_name'})}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="">
                    <Col span={12}>
                        <Form.Item
                            name="expense_type"
                            label="Loại chi phí"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                onChange={(value) => setFilter({...filter, expense_type: value})}
                                optionLabelProp="value"
                            >
                                {OptionsWithDescription([
                                        {
                                            name: 'Tất cả',
                                            value: null
                                        },
                                        ...(isIterable(config?.expense_type) ?
                                            config?.expense_type
                                            : [])
                                    ],
                                    {label: 'name', value: 'name', description: 'description'})}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Kế toán xử lý"
                            name="accountant_id"
                            initialValue={"Tất cả"}
                        >
                            <Select
                                allowClear
                                onChange={(value) => setFilter({...filter, accountant_id: value})}
                                optionLabelProp="label"
                            >
                                {OptionsWithDescription([
                                        {
                                            fullname: 'Tất cả',
                                            value: null
                                        },
                                        ...(isIterable(config?.personnel) ?
                                            config?.personnel
                                            : [])
                                    ],
                                    {label: 'fullname', value: 'uid', description: 'position_title'})}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="">
                    <Col span={12}>
                        <Form.Item
                            label="Ngày yêu cầu chi"
                            name="request_payment_date"
                        >
                            <DatePicker.RangePicker
                                //onChange={(value) => {console.log(value.map(date => date.startOf('day').toDate()))}}
                                onChange={(value) => {
                                    //console.log(value[0].format('DD-MM-YYYYT00:00:00Z'));

                                    //const utcDates = value.map(date => dayjs(date.startOf('day')).utc());
                                    /*
                                    value.forEach(date => {
                                        console.log(`${date.date()}/${date.month() + 1}/${date.year()}`);
                                    })
                                     */
                                    setFilter({...filter, request_payment_date: value?.map(date => date.format('YYYY-MM-DD'))})
                                }}
                                style={{width: '100%'}}
                                format="DD-MM-YYYY"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ngày kế toán duyệt chi"
                            name="approved_request_payment_date"
                        >
                            <DatePicker.RangePicker
                                onChange={(value) => setFilter({...filter, approved_request_payment_date: value?.map(date => date.format('YYYY-MM-DD'))})}
                                style={{width: '100%'}}
                                format="DD-MM-YYYY"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="">
                    <Col span={12}>
                        <Form.Item
                            label="Mùa vụ"
                            name="special_season"
                        >
                            <Select
                                allowClear
                                onChange={(value) => setFilter({...filter, special_season: value})}
                                optionLabelProp="value"
                            >
                                {OptionsWithDescription([
                                {
                                    name: 'Tất cả',
                                    value: null
                                },
                                ...(isIterable(config?.season) ?
                                config?.season
                                : [])
                                ],
                                {label: 'name', value: 'name', description: 'description'})}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ngày tạo yêu cầu"
                            name="created_at"
                        >
                            <DatePicker.RangePicker
                                onChange={(value) => setFilter({...filter, created_at: value?.map(date => date.format('YYYY-MM-DD'))})}
                                style={{width: '100%'}}
                                format="DD-MM-YYYY"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="">
                    <Col span={12}>
                        <Form.Item
                            label="Số tiền cần chi tối thiểu"
                            name="min_amount"
                        >
                            <InputNumber
                                defaultValue={null}
                                style={{width: '100%'}}
                                addonBefore=<IconMultiCurrency />
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => {
                                    //if value is null or blank, set to null
                                    if (!value) {
                                        value = null;
                                    }
                                    setFilter({...filter, min_amount: value})
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Số tiền cần chi tối đa"
                            name="max_amount"
                        >
                            <InputNumber
                                defaultValue={null}
                                style={{width: '100%'}}
                                addonBefore=<IconMultiCurrency />
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => {
                                    //if value is null or blank, set to null
                                    if (!value) {
                                        value = null;
                                    }
                                    setFilter({...filter, max_amount: value})
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="user_fault"
                            label=""
                            valuePropName="checked"
                            initialValue={filter.user_fault}
                        >
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFilter({...filter, user_fault: e.target.checked})
                                    } else {
                                        setFilter({...filter, user_fault: null})
                                    }
                                }}
                            >
                                Lọc những đề nghị user làm sai
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="is_tndn"
                            label=""
                            valuePropName="checked"
                            initialValue={filter.is_tndn}
                        >
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFilter({...filter, is_tndn: e.target.checked})
                                    } else {
                                        setFilter({...filter, is_tndn: null})
                                    }
                                }}
                            >
                                Mẫu 01-TNDN
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}

export default RequestFilter;