import api from "../../../Helpers/api-helper";
import {useEffect, useState} from "react";
import {Button, Divider, Flex, Space, Table, Tag, Upload} from "antd";
import Search from "antd/es/input/Search";
import {DownloadOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import AdminBankAccountEdit from "./AdminBankAccountEdit";
import {exportToExcel, parseExcelFile} from "../../Utils/SheetJS";
import {notify} from "../../../Helpers/toast-helper";
import AdminBankAccountUpload from "./AdminBankAccountUpload";

const AdminBankAccounts = () => {
    const [loading, setLoading] = useState(false)
    const [account, setAccount] = useState({
        data: [],
        immutable: []
    })

    const [editOpen, setEditOpen] = useState(false)
    const [editInfo, setEditInfo] = useState(null)

    const [uploadDta, setUploadData] = useState(null)

    const columns = [
        {
            title: 'Tên tài khoản',
            dataIndex: 'receiver_name',
            key: 'receiver_name',
            render: (text, record) => {
                return <a onClick={() => {
                    setEditInfo(record)
                }}>{text}</a>
            }
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'bank_account',
            key: 'bank_account',
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: 'Chi nhánh',
            dataIndex: 'bank_branch',
            key: 'bank_branch',
        },
        {
            title: 'Tỉnh/TP',
            dataIndex: 'bank_province',
            key: 'bank_province',
        },
        {
            title: 'Phân loại',
            dataIndex: 'uid',
            key: 'uid',
            render: (text) => {
                if (text) {
                    return <Tag color={'green'}>{text}</Tag>
                } else {
                    return <Tag color={'red'}>Public</Tag>
                }
            }
        }
    ]

    const fetchBankAccounts = async () => {
        setLoading(true)

        api.get('/bank-account/admin').then(response => {
            // Check if response is an array
            if (Array.isArray(response)) {
                setAccount({
                    data: response,
                    immutable: response
                })
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchBankAccounts()
    }, [])

    const handleSearch = (e) => {
        const s = e.target.value.trim()
        const reg = new RegExp(s, "gi");

        const filteredData = account.immutable.map(record => {
            const bank_account = record.bank_account.match(reg);
            const receiver_name = record.receiver_name.match(reg);

            if (!bank_account &&     !receiver_name) {
                return null;
            }
            return record;
        }).filter(record => !!record);

        setAccount({
            data: filteredData,
            immutable: account.immutable
        })
    }

    const handleEditClose = (reload = false) => {
        setEditOpen(false)
        setEditInfo(null)
        if (reload) {
            fetchBankAccounts()
        }
    }

    return (
        <>
            <div className="text-l text-bold mb-2">Danh mục tài khoản ngân hàng</div>
            <Flex justify="space-between" className="mb-2">
                <Space>
                    <Button
                        icon={<PlusOutlined/>}
                        type="primary"
                        onClick={() => setEditOpen(true)}
                    >
                        Thêm mới
                    </Button>
                    <Upload
                        showUploadList={false}
                        accept={'.xlsx, .xls'}
                        beforeUpload={async (file) => {
                            try {
                                const data = await parseExcelFile(file)
                                setUploadData(data)
                            } catch (e) {
                                console.error(e)
                                notify('error', 'Lỗi file tải lên')
                            }

                            return false
                        }}
                    >
                        <Button
                            icon={<UploadOutlined />}
                        >
                            Tải lên danh mục
                        </Button>
                    </Upload>
                    <Button
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            exportToExcel(account.data.map(item => {
                                ['uid', 'datetime', 'private', 'updatedAt'].forEach(key => {
                                    delete item[key]
                                })

                                //item.bank_account = item.bank_account?.toString() || ''

                                return item
                            }), 'Danh sách tài khoản ngân hàng')
                        }}
                    >
                        Tải về danh mục
                    </Button>
                </Space>
                <Search
                    style={{width: 300}}
                    placeholder="Tìm kiếm tài khoản ngân hàng"
                    allowClear
                    onChange={handleSearch}
                />
            </Flex>
            <Table
                columns={columns}
                dataSource={account.data}
                rowKey={'_id'}
                pagination={{
                    pageSizeOptions: [50, 100, 200],
                    showSizeChanger: true,
                }}
                pageSize={50}
            />
            <AdminBankAccountEdit
                open={editOpen}
                onClose={handleEditClose}
                editInfo={editInfo}
            />
            <AdminBankAccountUpload data={uploadDta} onCancel={setUploadData} />
        </>
    )
}

export default AdminBankAccounts;