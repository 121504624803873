const WhatsNew = () => {
    return (
        <></>
    )

    return (
        <div>
            <h3>Phiên bản mới này có gì? 🎉 🎉 🎉</h3>
            <ul>
                <li>
                    <p>Giờ đây bạn đã có thể lưu sẵn các mẫu thông tin tài khoản để sử dụng lại cho các đề nghị thanh toán một cách nhanh chóng.</p>
                    <p>Bạn có thể tạo và quản lý các mẫu thông tin tài khoản bằng cách truy cập menu "Mẫu thông tin tài khoản" ở bên trái, sau đó tạo, sửa xoá danh mục tài khoản.</p>
                    <p>Khi tạo đề nghị thanh toán, ở mục "Tên tài khoản nhận tiền" bạn có thể tìm kiếm các tài khoản đã lưu sẵn này và hệ thống sẽ tự động điền các thông tin còn lại cho bạn.</p>
                </li>

            </ul>
        </div>
    );
}

export default WhatsNew;